// toggleButton mixin
@mixin toggleButton {
	.onoffswitch {
		position: relative;
		width: 90px;
		user-select: none;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
	}
	.onoffswitch-checkbox {
		position: absolute;
		opacity: 0;
		pointer-events: none;
	}
	.onoffswitch-label {
		display: block;
		overflow: hidden;
		cursor: pointer;
		border: 2px solid #999999;
		border-radius: 20px;
	}
	.onoffswitch-inner {
		display: block;
		width: 200%;
		margin-left: -100%;
		transition: margin 0.3s ease-in 0s;
	}
	.onoffswitch-inner:before,
	.onoffswitch-inner:after {
		display: block;
		float: left;
		width: 50%;
		height: 30px;
		padding: 0;
		line-height: 30px;
		font-size: 14px;
		color: white;
		font-family: Trebuchet, Arial, sans-serif;
		font-weight: bold;
		box-sizing: border-box;
	}
	.onoffswitch-inner:before {
		content: "OFFC";
		padding-left: 10px;
		// background-color: #adc872;
		background-color: #557b83;
		color: #ffffff;
		text-align: left;
	}
	.onoffswitch-inner:after {
		content: "STGD";
		padding-right: 10px;
		background-color: #eeeeee;
		color: #999999;
		text-align: right;
	}
	.onoffswitch-switch {
		display: block;
		width: 18px;
		margin: 6px;
		background: #ffffff;
		position: absolute;
		top: 0;
		bottom: 0;
		right: 56px;
		border: 2px solid #999999;
		border-radius: 20px;
		transition: all 0.3s ease-in 0s;
	}
	.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
		margin-left: 0;
	}
	.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
		right: 0px;
	}
}

@mixin toolTip {
	/* Custom properties */
	:root {
		--tooltip-text-color: white;
		--tooltip-background-color: black;
		--tooltip-margin: 30px;
		--tooltip-arrow-size: 6px;
	}

	/* Wrapping */
	.Tooltip-Wrapper {
		display: inline-block;
		position: relative;
	}

	/* Absolute positioning */
	.Tooltip-Tip {
		position: absolute;
		border-radius: 4px;
		left: 50%;
		transform: translateX(-50%);
		padding: 6px;
		color: var(--tooltip-text-color);
		background: var(--tooltip-background-color);
		font-size: 14px;
		font-family: sans-serif;
		line-height: 1;
		z-index: 100;
		white-space: nowrap;
	}

	/* CSS border triangles */
	.Tooltip-Tip::before {
		content: " ";
		left: 50%;
		border: solid transparent;
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
		border-width: var(--tooltip-arrow-size);
		margin-left: calc(var(--tooltip-arrow-size) * -1);
	}

	/* Absolute positioning */
	.Tooltip-Tip.top {
		top: calc(var(--tooltip-margin) * -1);
	}
	/* CSS border triangles */
	.Tooltip-Tip.top::before {
		top: 100%;
		border-top-color: var(--tooltip-background-color);
	}

	/* Absolute positioning */
	.Tooltip-Tip.right {
		left: calc(100% + var(--tooltip-margin));
		top: 50%;
		transform: translateX(0) translateY(-50%);
	}
	/* CSS border triangles */
	.Tooltip-Tip.right::before {
		left: calc(var(--tooltip-arrow-size) * -1);
		top: 50%;
		transform: translateX(0) translateY(-50%);
		border-right-color: var(--tooltip-background-color);
	}

	/* Absolute positioning */
	.Tooltip-Tip.bottom {
		bottom: calc(var(--tooltip-margin) * -1);
	}
	/* CSS border triangles */
	.Tooltip-Tip.bottom::before {
		bottom: 100%;
		border-bottom-color: var(--tooltip-background-color);
	}

	/* Absolute positioning */
	.Tooltip-Tip.left {
		left: auto;
		right: calc(100% + var(--tooltip-margin));
		top: 50%;
		transform: translateX(0) translateY(-50%);
	}
	/* CSS border triangles */
	.Tooltip-Tip.left::before {
		left: auto;
		right: calc(var(--tooltip-arrow-size) * -2);
		top: 50%;
		transform: translateX(0) translateY(-50%);
		border-left-color: var(--tooltip-background-color);
	}
}
