@use "customMixins";
@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
// custom color values
// $primary: #445a67;
$primary: #a9a9a9;
$sky: #caebf2;
$watermelon: #ff3b3f;
$color_1: #fff;
$background-color_1: #060;
$background-color_2: #bbb;

// color palette for entire site
$lightSand: #e9decb;
$darkSand: #bbb4a2;
$darkGreen: #5d635f;
$darkBlue: #445a67;
$turtle_4: #557b83;
// $turtle_4: #adc872;
$turtle_5: #ccd1d2;
// $turtle_dark: #e5e5d9;
$turtle_dark: #f4f4ef;
$turtle_light: #588289;
// $turtle_light: #c1df7f;

.toggle-div {
	@include customMixins.toggleButton;
	position: relative;
	width: 90px;
}

// merge custom colors with theme colors
$custom-theme-colors: map-merge(
	$theme-colors,
	(
		primary: $primary,
		sky: $sky,
		watermelon: $watermelon,
		turtle_4: $turtle_4,
		turtle_5: $turtle_5,
		turtle_dark: $turtle_dark,
		turtle_light: $turtle_light,
	)
);

$theme-colors: map-merge($theme-colors, $custom-theme-colors);
@import "./node_modules/bootstrap/scss/bootstrap";
