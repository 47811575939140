html {
	background-color: #f4f4ef;
}
body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #283b3e;
	color: #283b3e;
}
@font-face {
	font-family: "Space Mono";
	src: local("SpaceMono"),
		url("./assets/fonts/SpaceMono/SpaceMono-Regular.ttf") format("truetype");
}
.spaceMono {
	font-family: "Space Mono";
}
code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}
/* Chrome, Safari, Edge, Opera */
.metricInput::-webkit-outer-spin-button,
.metricInput::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
.metricInput[type="number"] {
	-moz-appearance: textfield;
}

.input-dollar {
	position: relative;
}
.input-dollar.left input {
	padding-left: 18px;
}
.input-dollar.right input {
	padding-right: 18px;
	text-align: end;
}

.input-dollar:before {
	position: absolute;
	top: 0;
	content: "$";
}
.input-dollar.left:before {
	left: 5px;
}
.input-dollar.right:before {
	right: 5px;
}
